import React, { Component } from "react";
import Fade from "react-reveal/Fade";

import ReleaseCard from "../releases/ReleaseCard";
import VideoEmbed from "../utilities/VideoEmbed";
import ImageEmbed from "../utilities/ImageEmbed";

import "./#HanGaiden.css";
import releasesData from "../releases/Releases.json";

class HanGaiden extends Component {
  state = {
    contentHeight: 0,
  };

  componentDidMount() {
    // Wait for content to load
    setTimeout(() => {
      this.updateDimensions();
    }, 100);

    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", null);
  }

  updateDimensions() {
    if (document.getElementById("pekula_2")) {
      this.setState({
        contentHeight: document.getElementById("pekula_2").offsetHeight,
      });
    }
  }

  render() {
    const artist = this.props.artist;

    if (!artist) {
      return <h1>No such artist :/</h1>;
    }

    const releases = releasesData.releases.filter((release) => {
      return release.artistId === artist._id;
    });

    const releasesHeading = null;

    const bgElement = document.getElementById("background");
    const backgroundTopOffsetStr = bgElement
      ? window.getComputedStyle(bgElement, null)["top"]
      : null;
    const backgroundTopOffset = bgElement
      ? backgroundTopOffsetStr.substring(0, backgroundTopOffsetStr.length - 2)
      : null;

    // bg height = content height minus top offset + height of menu/logo - bottom offset
    const bgHeight = this.state.contentHeight - backgroundTopOffset + 331 - 400;

    return (
      <div id="hangaiden">
        <div className="row">
          <div
            id="background"
            style={{
              height: `${bgHeight + 300}px`,
            }}
          ></div>
        </div>

        <div className="row heading-container">
          <div className="col text-center">
            <Fade up distance="1%">
              <h1>{artist.name}</h1>
            </Fade>
          </div>
        </div>
        <div className="row justify-content-center banner-container">
          <div className="col-md-10">
            <Fade up distance="1%">
              <img
                alt={artist.name}
                src={artist.imageUrl}
                className="img-fluid"
                style={{
                  objectFit: "cover",
                  minWidth: "100%",
                  maxHeight: "600px",
                }}
              />
            </Fade>
          </div>
        </div>
        <div className="row justify-content-center about-text-container">
          <div
            className="col-md-8"
            style={{ color: "black", marginTop: "30px" }}
          >
            <Fade>
              <p>
                han gaiden: fresh new Oslo-based electronica trio; unstoppable
                force.
              </p>
              <p>
                The trio, consisting of Ragnhild Moan, Kristin Myhrvold and
                Ingrid Skåland Lia, has already made its mark on stages such as
                Novafest, Serendipfestivalen, Nasjonal jazzscene, Oslo
                jazzfestival, Ladyfest and Studentersamfundet (Trondheim).
              </p>
              <p>
                Their mercurial approach to vocal harmony, improvisation, and
                their command of beats was tempered by their tenure as students
                at the Norwegian Academy of Music, while their lyrical material
                features a raw and honest approach to the preoccupations of
                their youthful minds. They draw inspiration from house,
                experimental pop, R&B and ambient music, but never drawing
                exclusively from any single source at any given time. This
                intermeshing of styles creates a fresh sound, perfect for the
                new decade, distinct and immediately identifiable as han gaiden.
              </p>
              <p>
                Bullying; mistreatment; exclusion: few pass through life without
                being touched by this negativity. han gaiden's new single
                "squad" is concerned with the snapping point that comes and you
                decide "enough is enough – time to rise above it, be myself
                among people like me, and have a damn good time doing it!"
                Hiting the club with your squad is the preferred mode here, and
                the music reflects the sassy lyrics, with thumping heavy beats,
                a club-jumping squarewave bassline, faceslapping breaks, cheeky
                asides and a drenching of atitude. Gather your own squad
                (however you may in early 2020) and dance to take the power
                back!
              </p>
            </Fade>
          </div>
        </div>

        <Fade up distance="1%">
          <VideoEmbed vimeoId="424202678" />
          {/* <ImageEmbed src="/images/artists/pekula-vol-2-2.jpg" left small />
          <VideoEmbed vimeoId="305467043" right /> */}
          {/* <ImageEmbed src="/images/artists/pekula-vol-2-3.jpg" />
          <ImageEmbed src="/images/artists/pekula-vol-2-4.jpg" right />
          <ImageEmbed src="/images/artists/pekula-vol-2-5.jpg" left small />
          <ImageEmbed
            src="/images/artists/pekula-vol-2-6.jpg"
            rotate={90}
            right
          /> */}
        </Fade>

        <div
          className="row justify-content-center"
          // style={{
          //   marginBottom: "430px",
          // }}
        >
          <div className="col-auto">
            <div
              style={{
                color: "black",
                marginTop: "60px",
              }}
            >
              <Fade>
                <u>{releasesHeading} </u>
              </Fade>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {releases.map((release) => {
            return <ReleaseCard key={release._id} col={4} release={release} />;
          })}
        </div>
      </div>
    );
  }
}

export default HanGaiden;
