import React, { Component } from 'react';

import './AllReleases.css';

class AllReleases extends Component {
	render () {
		return (
			<div>All Releases here</div>
		);
	}
}

export default AllReleases;